import {
  buildCollection,
  buildProperty,
  EnumValuesChip,
  /*   buildEntityCallbacks,
  EntityOnSaveProps,
  EntityOnDeleteProps, */
} from "@firecms/core";

type MediaEntry = {
  file: string;
  type: string;
  title: string;
  description: string;
  alt_text: string;
  caption: string;
  created: Date;
  updated: Date;
};

const imageStorage = {
  mediaType: "image",
  storagePath: "images",
  acceptedFiles: ["image/*"],
  metadata: {
    cacheControl: "max-age=1000000",
  },
  imageCompression: {
    maxWidth: 800,
    quality: 80,
  },
};

const pdfStorage = {
  mediaType: "pdf",
  storagePath: "files",
  acceptedFiles: ["application/pdf"],
  metadata: {
    cacheControl: "max-age=1000000",
  },
};

function getStorage(type: string) {
  if (type === "image") {
    return imageStorage;
  } else if (type === "pdf") {
    return pdfStorage;
  }
}

export const mediaCollection = buildCollection<MediaEntry>({
  id: "media",
  name: "Media - 1.1 Testing DO NOT USE",
  path: "media",
  description: "1.1 Testing DO NOT USE",
  permissions: ({ authController, user }) => ({
    read: true,
    edit: true,
    create: true,
    delete: true,
  }),
  properties: {
    type: buildProperty({
      name: "Type",
      dataType: "string",
      editable: false,
      defaultValue: "image",
      required: true,
      enumValues: {
        image: "Image",
        pdf: "PDF",
      },
    }),
    title: buildProperty({
      name: "Title",
      description: "For Internal Organziation Purposes",
      dataType: "string",
      editable: false,
    }),
    description: buildProperty({
      name: "Description",
      description: "For Internal Organziation Purposes",
      dataType: "string",
      multiline: true,
      editable: false,
    }),
    alt_text: ({values}) => ({
      name: "Alt Text",
      description: "Image Alternate Text (Accessibility, Screenreaders, SEO)",
      dataType: "string",
      editable: false,
      disabled: values.type != "image",
    }),
    caption: ({values}) => ({
      name: "Caption",
      description: "Image Caption",
      dataType: "string",
      multiline: true,
      editable: false,
      disabled: values.type != "image",
    }),
    

    file: ({values}) => ({
      name: "File",
      dataType: "string",
      editable: false,
      required: true,
      storage: getStorage(values.type),
    }),

    created: buildProperty({
      name: "Created",
      dataType: "date",
      autoValue: "on_create",
      editable: false,
    }),
    updated: buildProperty({
      name: "Updated",
      dataType: "date",
      autoValue: "on_update",
      editable: false,
    }),
  },
});
