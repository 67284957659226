import {
  buildCollection,
  buildProperty,
  buildEntityCallbacks,
  EntityOnSaveProps,
  EntityOnDeleteProps,
} from "@firecms/core";
import MapboxLocation from "../form_fields/MapboxLocation";

import { TrailClosuresEntityView } from "./entity_views/TrailClosuresEntityView";
type TrailClosureEntry = {
  name: string;
  header_image: string;
  content: any[];
  region: string;
  status: string;
  closureStatus: string;
  created: Date;
  updated: Date;
  location: any;
  maps: any[];
};

const trailClosuresCallbacks = buildEntityCallbacks({
  onSaveSuccess: (props: EntityOnSaveProps<TrailClosureEntry>) => {
    fetch(`https://closures.pcta.org/api/revalidate?type=closure&id=${props.entityId}`);
  },
  onDelete: (props: EntityOnDeleteProps<TrailClosureEntry>) => {
    fetch(`https://closures.pcta.org/api/revalidate?type=closure&id=${props.entityId}`);
  },
});
export const trailClosuresCollection = buildCollection<TrailClosureEntry>({
  id: "trailClosures",
  name: "Trail Closures",
  path: "trailClosures",
  callbacks: trailClosuresCallbacks,
  textSearchEnabled: true,
  exportable: true,
  importable: true,
  permissions: ({ authController, user }) => ({
    read: true,
    edit: true,
    create: true,
    delete: true
  }),
  entityViews: [
    {
      key: "trail_closures_entity_view",
      name: "Preview Closure",
      Builder: TrailClosuresEntityView,
    },
  ],
  properties: {
    name: buildProperty({
      name: "Name",
      validation: { required: true },
      dataType: "string",
      editable: false,
    }),
    region: buildProperty({
      name: "Region",
      validation: { required: true },
      dataType: "string",
      columnWidth: 140,
      enumValues: {
        "Southern California": "Southern California",
        "Central California": "Central California",
        "Northern California": "Northern California",
        Oregon: "Oregon",
        Washington: "Washington",
      },
      defaultValue: "Southern California",
      editable: false,
    }),
    status: buildProperty(({ values }) => ({
      name: "Status",
      validation: { required: true },
      dataType: "string",
      columnWidth: 140,
      enumValues: {
        published: {
          id: "published",
          label: "Published",
          //disabled: !values.header_image,
        },
        draft: "Draft",
        archived: "Archived",
      },
      defaultValue: "draft",
      editable: false,
    })),
    closureStatus: buildProperty(({ values }) => ({
      name: "Closure Status",
      validation: { required: true },
      dataType: "string",
      columnWidth: 140,
      editable: false,
      enumValues: {
        closed: {
          id: "closed",
          label: "Closed",
          color: "redDark",
        },
        alert: {
          id: "alert",
          label: "Alert",
          color: "orangeDark",
        },
        reopened: {
          id: "reopened",
          label: "Reopened",
          color: "greenDark",
        },
      },
      defaultValue: "closed",
    })),
    header_image: buildProperty({
      name: "Thumbnail Image",
      dataType: "string",
      editable: false,
      storage: {
        mediaType: "image",
        storagePath: "images",
        acceptedFiles: ["image/*"],
        metadata: {
          cacheControl: "max-age=1000000",
        },
        imageCompression: {
          maxWidth: 800,
          quality: 80,
        },
      },
    }),
    maps: buildProperty({
      // iframes
      name: "Additional Maps",
      description:
        "Additional Maps beyond the default offline map. Some options are online only.",
      dataType: "array",
      columnWidth: 140,
      editable: true,
      hideFromCollection: true,
      oneOf: {
        properties: {
          link: buildProperty({
            editable: false,
            dataType: "map",
            name: "Map Link (ONLINE ONLY)",
            properties: {
              label: {
                name: "Label",
                dataType: "string",
                editable: false,
                required: true,
              },
              url: {
                name: "URL",
                dataType: "string",
                url: true,
                editable: false,
                required: true,
              },
            },
          }),
          pdf: buildProperty({
            editable: false,
            dataType: "map",
            name: "Map PDF",
            properties: {
              label: {
                name: "Link Friendly Text (Label)",
                dataType: "string",
                editable: false,
                required: true,
                validation: { required: true },
              },
              file: {
                name: "PDF File",
                dataType: "string",
                required: true,
                validation: { required: true },
                storage: {
                  mediaType: "pdf",
                  storagePath: "files",
                  acceptedFiles: ["application/pdf"],
                  metadata: {
                    cacheControl: "max-age=1000000",
                  },
                },
              },
            },
          }),
        },
      },
    }),
    content: buildProperty({
      name: "Content",
      description: "Body Content of the Closure",
      validation: { required: true },
      dataType: "array",
      columnWidth: 400,
      editable: false,
      oneOf: {
        typeField: "type",
        valueField: "value",
        properties: {
          text: buildProperty({
            dataType: "string",
            name: "Text",
            multiline: true,
            editable: false,
          }),
          heading_1: buildProperty({
            dataType: "string",
            name: "Heading 1",
            editable: false,
          }),
          heading_2: buildProperty({
            dataType: "string",
            name: "Heading 2",
            editable: false,
          }),
          /* link: buildProperty({
                        dataType: "string",
                        name: "Link",
                        url: true
                    }), */
          link: {
            name: "Link",
            description: "Hyperlink (Online Only)",
            dataType: "map",
            editable: false,
            properties: {
              label: {
                name: "Link Text",
                dataType: "string",
                editable: false,
                required: true,
              },
              url: {
                name: "URL",
                dataType: "string",
                url: true,
                editable: false,
                required: true,
              },
            },
            expanded: true,
          },
          email: buildProperty({
            name: "Email",
            description: "Email",
            dataType: "map",
            editable: false,
            properties: {
              label: {
                name: "Label",
                dataType: "string",
                editable: false,
                required: true,
              },
              email: {
                name: "Email Address",
                dataType: "string",
                email: true,
                editable: false,
                required: true,
              },
            },
            expanded: true,
          }),
          image: buildProperty({
            name: "Image",
            dataType: "string",
            editable: false,
            storage: {
              mediaType: "image",
              storagePath: "images",
              acceptedFiles: ["image/*"],
              metadata: {
                cacheControl: "max-age=1000000",
              },
              imageCompression: {
                maxWidth: 2560,
                quality: 80,
              },
            },
            validation: { required: true },
          }),
          pdf: {
            name: "PDF",
            description: "PDF",
            dataType: "map",
            editable: false,
            properties: {
              label: {
                name: "PDF Label Text",
                dataType: "string",
                required: true,
                validation: { required: true },
              },
              file: {
                name: "PDF",
                dataType: "string",
                required: true,
                validation: { required: true },
                storage: {
                  mediaType: "pdf",
                  storagePath: "files",
                  acceptedFiles: ["application/pdf"],
                  metadata: {
                    cacheControl: "max-age=1000000",
                  },
                },
              },
            },
            expanded: true,
          },
          media: {
            name: "Media 1.1 Testing DO NOT USE",
            dataType: "reference",
            description: "1.1 Testing DO NOT USE",
            path: "media",
            previewProperties: ["file"],
          }
        },
      },
    }),
    location: buildProperty({
      name: "Location",
      description: "Location of the Closure",
      dataType: "map",
      validation: { required: true },
      editable: false,
      hideFromCollection: true,
      Field: MapboxLocation,
      properties: {
        lat: {
          name: "Latitude",
          dataType: "number",
          validation: { required: true, min: -90, max: 90 },
          editable: false,
          defaultValue: 39.78915298789167
        },
        lng: {
          name: "Longitude",
          dataType: "number",
          validation: { required: true, min: -180, max: 180 },
          editable: false,
          defaultValue: -121.3942389384216
        },
      },
    }),

    created: buildProperty({
      name: "Created",
      dataType: "date",
      autoValue: "on_create",
      editable: false,
    }),
    updated: buildProperty({
      name: "Updated",
      dataType: "date",
      autoValue: "on_update",
      editable: false,
    }),
  },
  initialFilter: { status: ["!=", "archived"] },
  initialSort: ["updated", "desc"],
  indexes: [
    {
      status: 'desc',
      updated: 'desc',
      __name__: 'desc'
    }
  ],

});
